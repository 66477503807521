<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12">
    <div *ngIf="article">

      <!--header section-->
      <div class="d-flex flex-row justify-content-center">
        <div class="d-flex flex-column">
          <div class="pt-5"><span class="display-4">{{article.headline}}</span></div>
          <i class="mb-2">published: {{article.datePublishedInMillis/1000  | amFromUnix | amDateFormat:'LL'}}</i>
        </div>

        <!--<div class="mt-3">
          <hr/>
          <span>Author: {{article.authorName}} in </span><span class="">{{article.articleTypeName}}</span>
          <hr/>
        </div>-->
        <!--headerphoto or video-->
        <!--<div *ngIf="isNotEmpty(article.headerVideoEmbedSrc)" class="d-flex justify-content-center">
          <iframe width="560" height="315"
                  [src]="sanitiseVideoUrl(article.headerVideoEmbedSrc)"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>

          </iframe>
        </div>-->
        <!--<div *ngIf="isNotEmpty(article.editorialPic)" class="mt-3">
          <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
               alt="article pic"
               [src]="article.editorialPic"
          >
        </div>-->
      </div>

      <!--summary section-->
      <!--<div *ngIf="isNotEmpty(article.summary)" class="shadow p-5 mt-3">
        <span [innerHTML]="textFormatService.autoLinkText(article.summary) | nl2br: true"></span>
      </div>-->

      <!--Content section  implement switchcase for different types of article content  -->
      <!--<section *ngIf="isNotEmpty(article.section1ImageUrl)" class="mt-3">
        <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
             alt="article pic"
             [src]="article.section1ImageUrl"
        >
      </section>
      <div *ngIf="isNotEmpty(article.section1VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
        <iframe width="560" height="315"
                [src]="sanitiseVideoUrl(article.section1VideoEmbedSrc)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>

        </iframe>
      </div>-->
      <div></div>
      <section class="row" *ngFor="let articleContent of article.content" [ngSwitch]="articleContent.contentTypeId">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"  *ngSwitchCase="2" [src]="articleContent.imageUrl"/>

          <p [innerHTML]="textFormatService.autoLinkText(articleContent.textContent) | nl2br: true"></p>
        </div>
        <div class="col-md-1"></div>
      </section>


      <!--body section two-->
      <!--<div>
        <div *ngIf="isNotEmpty(article.section2ImageUrl)" class="mt-3">
          <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
               alt="article pic"
               [src]="article.section2ImageUrl"
          >
        </div>
        <div *ngIf="isNotEmpty(article.section2VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
          <iframe width="560" height="315"
                  [src]="sanitiseVideoUrl(article.section2VideoEmbedSrc)"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>

          </iframe>
        </div>
        <div class="mt-5">
          <p [innerHTML]="textFormatService.autoLinkText(article.section2Body) | nl2br: true"></p>
        </div>
      </div>-->

      <!--section 3-->
      <!--<div>
        <div *ngIf="isNotEmpty(article.section3ImageUrl)" class="mt-3">
          <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
               alt="article pic"
               [src]="article.section3ImageUrl"
          >
        </div>
        <div *ngIf="isNotEmpty(article.section3VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
          <iframe width="560" height="315"
                  [src]="sanitiseVideoUrl(article.section3VideoEmbedSrc)"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>

          </iframe>
        </div>
        <div class="mt-5">
          <p [innerHTML]="textFormatService.autoLinkText(article.section3Body) | nl2br: true"></p>
        </div>
      </div>-->


      <div>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>

