import {Component, OnInit} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {environment} from "../environments/environment.prod";
import {UserService} from "./service/user/user.service";
import {UserDataModel} from "./model/userModel";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {AppState} from "./app.state";
import {Store} from "@ngrx/store";
import {SetAuthenticationToken, SetUserAction} from "./component/user/user.action";

declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // title = 'Christianity.ng';
  routerSub: Subscription;
  constructor(private cookieService: CookieService,
              private store: Store<AppState>,
              private userService: UserService,
              private router: Router ) {
    this.routerSub = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    })

  }

  ngOnInit(): void {
    const jwtToken = this.cookieService.get(environment.jwtTokenCookieName);
    const userId = this.cookieService.get(environment.userIdCookieName);

    if (jwtToken) {
      this.store.dispatch(new SetAuthenticationToken({token: jwtToken}));
    }
    if (userId) {
      this.userService.getUser(+userId)
        .subscribe((user: UserDataModel) => {
          this.userService.storeUserAndWriteCookie(user);
          this.store.dispatch(new SetUserAction({user: user}))
        })
    }
  }
}
