import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NewsListComponent} from "./news-list/news-list.component";
import {NewsCreateComponent} from "./news-create/news-create.component";
import {NewsViewComponent} from "./news-view/news-view.component";
import {NewsPhotoEditComponent} from "./news-photo-edit/news-photo-edit.component";


const routes: Routes = [
  {
    path: 'news',
    component: NewsListComponent
  },
  {
    path: 'news/:id',
    component: NewsViewComponent
  },
  {
    path: 'news/item/edit/:id',
    component: NewsCreateComponent
  },
  {
    path: 'news/create/item',
    component: NewsCreateComponent
  },
  {
    path: 'news/item/edit/photo/:id',
    component: NewsPhotoEditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
