<div class="row mt-3">
  <div *ngFor="let datingUser of datingUsers" class="col-sm-12 col-md-3">
    <div class="card mb-4 box-shadow border-0">
      <a [routerLink]="'/user/view/'+datingUser?.id">
        <img class="card-img-top custom-img-fluid shadow"
             alt="article pic"
             [src]="datingUser?.userHeaderPic"
             width="200px"
             height="200px"
             style="min-width: 150px; min-height: 150px"
        >
      </a>
      <div class="card-body pl-0 pr-0 pt-2 pb-0">
        <a [routerLink]="'/user/view/'+datingUser.id"><h5
          class="card-title">{{datingUser?.name}}</h5></a>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center w-100">
    <button class="btn btn-lg btn-secondary" (click)="more()"><span>More</span></button>
  </div>
</div>
