import {Component, OnInit} from '@angular/core';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {Router} from "@angular/router";
import {UserDataModel} from "../../../model/userModel";
import {SetUserAction} from "../user.action";

@Component({
  selector: 'app-user-setting-notification',
  templateUrl: './user-setting-notification.component.html',
  styleUrls: ['./user-setting-notification.component.scss']
})
export class UserSettingNotificationComponent implements OnInit {
  faArrowLeft: any;
  userData: UserDataModel;
  prayerNotificationModel: number;
  newsNotificationModel: number;
  editorialNotificationModel: number;

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private router: Router) {
    store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
        this.prayerNotificationModel = this.userData.prayerNotification;
        this.newsNotificationModel = this.userData.newsNotification;
        this.editorialNotificationModel = this.userData.editorialNotification;
      }
    });

    this.faArrowLeft = faArrowLeft;

  }

  ngOnInit() {
  }

  submit() {
    this.userService.updateNotificationSettings(
      +this.prayerNotificationModel,
      +this.newsNotificationModel,
      +this.editorialNotificationModel,
      this.userData.id).subscribe((user: UserDataModel) => {
      this.store.dispatch(new SetUserAction({user}));
      this.router.navigate(['/user/setting'])
    })

  }
}
