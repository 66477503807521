import {DatingNetwork, UserDataModel, UserModel} from "../../model/userModel";
import * as UserActions from './user.action'
import {ADD_DATING_NETWORK_USERS} from './user.action'
import {tassign} from "tassign";

export let initialState: UserModel = {
  user: null,
  authentication: {token: null, role: null, id: null},
  datingNetwork: {
    users: [],
    page: 0
  }

};

export function reducer(state: UserModel = initialState, action: UserActions.Actions): UserModel {
  switch (action.type) {
    case "[AUTHENTICATION] SET_AUTHENTICATION":
      return tassign(state, {authentication: action.payload.authentication});
    case "[AUTHENTICATION] SET_AUTHENTICATION_TOKEN":
      const mergedToken = tassign(state.authentication, {token: action.payload.token})
      return tassign(state, {authentication: mergedToken});
    case "[USER] SET_USER":
      return tassign(state, {user: action.payload.user});
    case "[USER] DELETE_USER":
      return tassign(state, {user: null})
    case "[USER] ADD_DATING_NETWORK_USERS":
      const datingNetwork: DatingNetwork =
        tassign(state.datingNetwork, {users: state.datingNetwork.users.concat(action.payload)})
      return tassign(state, {datingNetwork: datingNetwork});
    case "[USER] SET_DATING_NETWORK_PAGE":
      const datingNetworkWithPage: DatingNetwork =
        tassign(state.datingNetwork, {page: action.payload})
      return tassign(state, {datingNetwork: datingNetworkWithPage});
    default:
      return state;
  }
}
