import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CommunityModel} from "../../model/community.model";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {CommunityTransformer} from "../../transformer/community-transformer";

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  constructor(private httpClient: HttpClient) {}

  getCommunity(id:number,name: string): Observable<CommunityModel> {
    let url = `/web/api/community/${id}/${name}`
    return this.httpClient.get(url).pipe(
      take(1),
      map(value => CommunityTransformer.transformCommunity(value))
    )
  }

  getCommunities(): Observable<CommunityModel[]> {
    let url = `/web/api/community`
    return this.httpClient.get(url).pipe(
      map((value: any[]) => CommunityTransformer.transformCommunities(value))
    )
  }
}
