import {Component, OnDestroy, OnInit} from '@angular/core';
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {faSpinner, faDotCircle} from "@fortawesome/free-solid-svg-icons";
import {TextFormatService} from "../../../service/text-format/text-format.service";
import {ActivatedRoute} from "@angular/router";
import {CommunityService} from "../../../service/community/community.service";
import {CommunityModel} from "../../../model/community.model";
import {ArticleService} from "../../../service/article/article.service";
import {ArticleModel} from "../../../model/article.model";

@Component({
  selector: 'app-community-home',
  templateUrl: './community-home.component.html',
  styleUrls: ['./community-home.component.scss']
})
export class CommunityHomeComponent implements OnInit, OnDestroy {
  faSpinner; faDotCircle: any;
  community: CommunityModel;

  constructor(private store: Store<AppState>,
              public textFormatService: TextFormatService,
              private activatedRoute: ActivatedRoute,
              private communityService: CommunityService) {
    this.faSpinner = faSpinner;
    this.faDotCircle = faDotCircle;
  }

  ngOnInit() {
    const communityName = this.activatedRoute.snapshot.params.name;
    const id = this.activatedRoute.snapshot.params.id;
    this.getCommunity(id,communityName);
  }

  getCommunity(id:number,name: string) {
    this.communityService.getCommunity(id,name)
      .subscribe((value: CommunityModel) => {
        this.community = value;
      });
  }

  ngOnDestroy(): void {
  }


}
