<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">

    <div *ngFor="let community of communities">
      <a [routerLink]="'/c/'+community?.id+'/'+community.systemName">
        <h2 class="card-title">{{community?.name}}</h2></a>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
