import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {UserSettingComponent} from "./user-setting/user-setting.component";
import {UserDeactivateComponent} from "./user-deactivate/user-deactivate.component";
import {UserSettingNotificationComponent} from "./user-setting-notification/user-setting-notification.component";
import {UserViewComponent} from "./user-view/user-view.component";
import {UserProfileEditComponent} from "./user-profile-edit/user-profile-edit.component";
import {UserPictureEditComponent} from "./user-picture-edit/user-picture-edit.component";
import {UserGalleryComponent} from "./user-gallery/user-gallery.component";
import {UserPasswordSettingComponent} from "./user-password-setting/user-password-setting.component";
import {UserAccountComponent} from "./user-account/user-account.component";
import {UserCommunityHomeComponent} from "./user-community-home/user-community-home.component";
import {UserArticleMgmtComponent} from "../article/user-article-mgmt/user-article-mgmt.component";
import {UserDirectoryMgmtComponent} from "../directory/user-directory-mgmt/user-directory-mgmt.component";
import {UserViewHomeComponent} from "./user-view-home/user-view-home.component";
import {UserViewVidsComponent} from "./user-view-vids/user-view-vids.component";
import {UserSettingHomeComponent} from "./user-setting-home/user-setting-home.component";
import {DatingHomeComponent} from "./dating-home/dating-home.component";
import {DatingProfileEditComponent} from "./dating-profile-edit/dating-profile-edit.component";


const routes: Routes = [
  {
    path: 'user/view/:id',
    component: UserViewComponent,
    children: [
      {
        path: '', // child route path
        pathMatch: 'full',
        component: UserViewHomeComponent, // child route component that the router renders
      },
      {
        path: 'gallery',
        component: UserGalleryComponent, // another child route component that the router renders
      },{
        path: 'videos',
        component: UserViewVidsComponent, // another child route component that the router renders
      },
    ]
  },
  {
    path: 'user/account',
    component: UserProfileComponent
  },
/*  {
    path: 'user/setting',
    component: UserSettingComponent
  },*/
/*  {
    path: 'user/setting/deactivate',
    component: UserDeactivateComponent
  },
  {
    path: 'user/setting/notifications',
    component: UserSettingNotificationComponent
  },
  {
    path: 'user/setting/general',
    component: UserProfileEditComponent
  },
  {
    path: 'user/setting/pictures',
    component: UserPictureEditComponent
  },
  {
    path: 'user/setting/password',
    component: UserPasswordSettingComponent
  },*/
  {
    path: 'user',
    component: UserAccountComponent,
    children: [
      {
        path: '', // child route path
        component: UserCommunityHomeComponent,
      },
      {
        path: 'profile/:id',
        component: UserProfileComponent,
        children: [
          {
            path: '', // child route path
            pathMatch: 'full',
            component: UserViewHomeComponent, // child route component that the router renders
          },
          {
            path: 'gallery',
            component: UserGalleryComponent, // another child route component that the router renders
          },
          {
            path: 'videos',
            component: UserViewVidsComponent, // another child route component that the router renders
          },
        ]
      },
      {
        path: 'setting',
        component: UserSettingHomeComponent,
        children: [
          {
            path: '',
            component: UserSettingComponent
          },
          {
            path: 'general',
            component: UserProfileEditComponent
          },
          {
            path: 'dating',
            component: DatingProfileEditComponent
          },
          {
            path: 'deactivate',
            component: UserDeactivateComponent
          },
          {
            path: 'notifications',
            component: UserSettingNotificationComponent
          },
          {
            path: 'pictures',
            component: UserPictureEditComponent
          },
          {
            path: 'password',
            component: UserPasswordSettingComponent
          }
        ]
      },
      {
        path: 'articles',
        component: UserArticleMgmtComponent,
      },
      {
        path: 'directory',
        component: UserDirectoryMgmtComponent,
      },
      {
        path: 'dating',
        component: DatingHomeComponent,
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
