import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserViewComponent } from './user-view/user-view.component';
import {StoreModule} from "@ngrx/store";
import * as UserReducer from './user.reducer';
import { UserSettingComponent } from './user-setting/user-setting.component'
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { UserDeactivateComponent } from './user-deactivate/user-deactivate.component';
import { UserSettingNotificationComponent } from './user-setting-notification/user-setting-notification.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MomentModule} from "ngx-moment";
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import { UserPictureEditComponent } from './user-picture-edit/user-picture-edit.component';
import { UserGalleryComponent } from './user-gallery/user-gallery.component';
import { UserPasswordSettingComponent } from './user-password-setting/user-password-setting.component';
import { UserAccountComponent } from './user-account/user-account.component';
import {ArticleModule} from "../article/article.module";
import {DirectoryModule} from "../directory/directory.module";
import {CommunityModule} from "../community/community.module";
import { UserViewVidsComponent } from './user-view-vids/user-view-vids.component';
import { UserViewHomeComponent } from './user-view-home/user-view-home.component';
import {Nl2BrPipeModule} from "nl2br-pipe";
import { UserSettingHomeComponent } from './user-setting-home/user-setting-home.component';
import {UserCommunityHomeComponent} from "./user-community-home/user-community-home.component";
import {SpinnerModule} from "../spinner/spinner.module";
import { DatingHomeComponent } from './dating-home/dating-home.component';
import { DatingProfileEditComponent } from './dating-profile-edit/dating-profile-edit.component';

@NgModule({
  declarations: [
    UserProfileComponent, UserViewComponent, UserSettingComponent, UserDeactivateComponent, UserSettingNotificationComponent,
    UserProfileEditComponent, UserPictureEditComponent, UserGalleryComponent, UserPasswordSettingComponent,
    UserAccountComponent,
    UserViewVidsComponent,
    UserViewHomeComponent,
    UserSettingHomeComponent,
    UserCommunityHomeComponent,
    DatingHomeComponent,
    DatingProfileEditComponent
  ],
    imports: [
        CommonModule,
        UserRoutingModule,
        StoreModule.forFeature('user', UserReducer.reducer),
        FontAwesomeModule,
        FormsModule,
        MomentModule,
        ReactiveFormsModule,
        CommunityModule,
        ArticleModule,
        DirectoryModule,
        Nl2BrPipeModule,
      SpinnerModule
    ]
})
export class UserModule { }
