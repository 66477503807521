import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../../service/user/user.service";
import {UserDataModel} from "../../../model/userModel";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {AddDatingNetworkUsers, SetDatingNetworkPage} from "../user.action";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-dating-home',
  templateUrl: './dating-home.component.html',
  styleUrls: ['./dating-home.component.scss']
})
export class DatingHomeComponent implements OnInit, OnDestroy {

  datingUsers: UserDataModel[] = [];
  pageNumber: number = 0;
  datingNetworkUsersSub: Subscription;

  constructor(private userService: UserService,
              private store: Store<AppState>) {
    this.datingNetworkUsersSub = this.store.select('user')
      .subscribe(value => {
        this.datingUsers = value.datingNetwork.users;
        this.pageNumber = value.datingNetwork.page;
      });
  }

  ngOnInit() {
    if (this.datingUsers.length === 0) {
      this.getDatingNetworkUsers(this.pageNumber);
    }
  }

  ngOnDestroy() {
    this.datingNetworkUsersSub.unsubscribe();
  }

  getDatingNetworkUsers(pageNumber: number){
    this.userService.getDatingNetworkUsers(pageNumber)
      .subscribe((result: UserDataModel[]) => {
        this.store.dispatch(new AddDatingNetworkUsers(result))
      })
  }

  more() {
    const pn = ++this.pageNumber;
    this.getDatingNetworkUsers(pn);
    this.store.dispatch(new SetDatingNetworkPage(pn));

  }
}
