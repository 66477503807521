import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductRoutingModule } from './product-routing.module';
import { ProductAddComponent } from './product-add/product-add.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProductService} from "../../service/product/product.service";
import {HttpClientModule} from "@angular/common/http";
import { ProductViewComponent } from './product-view/product-view.component';
import { ProductListComponent } from './product-list/product-list.component';
import {Nl2BrPipeModule} from "nl2br-pipe";


@NgModule({
  declarations: [ProductAddComponent, ProductViewComponent, ProductListComponent],
  imports: [
    CommonModule,
    ProductRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    Nl2BrPipeModule
  ],
  providers: [ProductService],
  exports: [ProductAddComponent, ProductViewComponent, ProductListComponent]
})
export class ProductModule { }
