import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NewsService} from "../service/news.service";
import {NewsModel} from "../../../model/news.model";
import {PicTypeEnum} from "../../../service/article/article.service";

@Component({
  selector: 'app-news-photo-edit',
  templateUrl: './news-photo-edit.component.html',
  styleUrls: ['./news-photo-edit.component.scss']
})
export class NewsPhotoEditComponent implements OnInit {
  news: NewsModel;
  id;
  headerPhoto: File;
  displayPhoto: File;
  section1Photo: File;
  section2Photo: File;

  headerType = PicTypeEnum.HEADER;
  displayType = PicTypeEnum.DISPLAY;
  section1Type = PicTypeEnum.SECTION_1;
  section2Type = PicTypeEnum.SECTION_2;

  constructor(private activatedRoute: ActivatedRoute,
              private newsService: NewsService,
              private router: Router) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;
    this.getNews(this.id);
  }

  getNews(id: number) {
    this.newsService.getNews(id)
      .subscribe(news => {
        this.news = news;
      })
  }

  onChangePhoto(event, type: PicTypeEnum) {
    switch (type) {
      case PicTypeEnum.DISPLAY:
        this.displayPhoto = event.target.files[0];
        break;
      case PicTypeEnum.HEADER:
        this.headerPhoto = event.target.files[0];
        break;
      case PicTypeEnum.SECTION_1:
        this.section1Photo = event.target.files[0];
        break;
      case PicTypeEnum.SECTION_2:
        this.section2Photo = event.target.files[0];
        break;
      default:
        this.displayPhoto = event.target.files[0];
        break;
    }
  }

  submit(photo: File, type: PicTypeEnum){
    let form = new FormData();
    if (photo) {
      form.append('pic', photo, photo.name);
    }else{
      // form.append('pic', undefined, undefined);
    }

    this.newsService.editNewsPhotos(this.id, form, type)
      .subscribe(res => {
        //route to the user profile
        this.router.navigate(['/user'])
      })
  }
}
