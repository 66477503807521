import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './component/home/home.component';
import {NavbarComponent} from './component/navbar/navbar.component';
import {RegistrationModule} from "./component/registration/registration.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AboutModule} from "./component/about/about.module";
import {LoginModule} from "./component/login/login.module";
import {JwtTokenInterceptorService} from "./interceptor/jwt-token-interceptor.service";
import {UserModule} from "./component/user/user.module";
import {CookieService} from "ngx-cookie-service";
import {CompanyModule} from "./component/company/company.module";
import {ProductModule} from "./component/product/product.module";
import {AccountModule} from "./component/account/account.module";
import {CommunityModule} from "./component/community/community.module";
import {StoreModule} from "@ngrx/store";
import {NewsModule} from "./component/news/news.module";
import {HomeModule} from "./component/home/home.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatFormFieldModule} from "@angular/material/form-field";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment.prod";

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    NavbarComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    RegistrationModule,
    HomeModule,
    AboutModule,
    LoginModule,
    UserModule,
    CompanyModule,
    ProductModule,
    AccountModule,
    CommunityModule,
    NewsModule,
    AppRoutingModule,
    StoreModule.forRoot({
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    BrowserAnimationsModule,
    MatFormFieldModule
  ],
/*  exports: [
    MatFormFieldModule
  ],*/
  providers: [
    CookieService,
    {provide: HTTP_INTERCEPTORS,
    useClass: JwtTokenInterceptorService,
    multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
