import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NewsService} from "../service/news.service";
import {NewsModel} from "../../../model/news.model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss']
})
export class NewsCreateComponent implements OnInit {
  formGroup: any;
  mode: string;
  news: NewsModel;
  newsId;

  constructor(private newsService: NewsService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.formGroup = new FormGroup({
      headline: new FormControl('', [Validators.required]),
      headerVid: new FormControl('', []),
      section1Body: new FormControl('', [Validators.required]),
      section1Vid: new FormControl('', []),
      section2Body: new FormControl('', []),
      section2Vid: new FormControl('', []),
      headerPic: new FormControl('', []),
      displayPic: new FormControl('', []),
      section2Img: new FormControl('', []),
      section1Img: new FormControl('', [])
    })
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params.id) {
      this.newsId = this.activatedRoute.snapshot.params.id;
      this.mode = 'edit';
      this.getNews();
    }
  }

  edit() {

  }

  submit() {
    if (this.formGroup.valid) {
      let newsData: NewsModel = this.transformNewsData(this.formGroup);
      this.newsService.postNews(newsData)
        .subscribe(news => {
          this.news = news;
        });
    }
  }

  saveEdit(){
    let news = this.transformNewsData(this.formGroup);
    news.id = this.newsId;
    this.newsService.editNews(news)
      .subscribe(res => {
        this.router.navigate(['/user'])
      })
  }

  transformNewsData(formGroup: FormGroup): NewsModel {
    return {
      headline: formGroup.value.headline,
      id: null,
      displayPic: formGroup.value.displayPic,
      headerPic: formGroup.value.headerPic,
      headerVid: formGroup.value.headerVid,

      section1Body: formGroup.value.section1Body,
      section1Vid: formGroup.value.section1Vid,
      section1Img: formGroup.value.section1Img,

      section2Body: formGroup.value.section2Body,
      section2Vid: formGroup.value.section2Vid,
      section2Img: formGroup.value.section2Img
    }
  }

  getNews(){
    this.newsService.getNews(this.newsId)
      .subscribe((news: NewsModel) => {
        this.setForm(news);
      });
  }

  setForm(news: NewsModel){
    this.formGroup.get('headline').setValue(news.headline);
    this.formGroup.get('headerPic').setValue(news.headerPic);
    this.formGroup.get('headerVid').setValue(news.headerVid);
    this.formGroup.get('displayPic').setValue(news.displayPic);
    this.formGroup.get('section1Body').setValue(news.section1Body);
    this.formGroup.get('section1Vid').setValue(news.section1Vid);
    this.formGroup.get('section1Img').setValue(news.section1Img);
    this.formGroup.get('section2Body').setValue(news.section2Body);
    this.formGroup.get('section2Vid').setValue(news.section2Vid);
    this.formGroup.get('section2Img').setValue(news.section2Img);
  }
}
