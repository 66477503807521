import { Component, OnInit } from '@angular/core';
import {CompanyService} from "../service/company.service";
import {CompanyModel} from "../model/company.model";
import {ActivatedRoute} from "@angular/router";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {faEnvelope, faDotCircle} from "@fortawesome/free-solid-svg-icons";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {FellowshipModel} from "../../../model/fellowship.model";

enum PageMode {
  PRODUCT
}

@Component({
  selector: 'app-corporate-view',
  templateUrl: './corporate-view.component.html',
  styleUrls: ['./corporate-view.component.scss']
})
export class CorporateViewComponent implements OnInit {
  company: CompanyModel;
  companyId: number;
  userId;
  faEnvelope: any;
  faDotCircle: any;
  selectedPage: string = 'home';
  homePageTag = 'home';
  productsPageTag = 'products';
  pageMode
  fellowshipList: FellowshipModel[];
  constructor(private companyService: CompanyService,
              private activatedRoute: ActivatedRoute,
              private store: Store<AppState>,
              private fellowshipService: FellowshipService) {
    this.faEnvelope = faEnvelope;
    this.faDotCircle = faDotCircle;
  }

  ngOnInit() {
    this.companyId = this.activatedRoute.snapshot.params.id;
    this.companyService.getCompany(this.companyId)
      .subscribe((company: CompanyModel) => {
        this.company = company;
      })

    this.fellowshipService.getFellowshipListForOrganisation(this.companyId)
      .subscribe((fellowships: FellowshipModel[]) => {
        this.fellowshipList = fellowships;
      })
  }

  notNull(value: string) {
    return value;
  }

  setPage(page: string) {
    this.selectedPage = page;
  }

  showPage(page: string = 'home'){
    return this.selectedPage === page;
  }
}
