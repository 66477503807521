import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {CompanyService} from "../service/company.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationService} from "../../../service/location/location.service";
import {CompanyModel} from "../model/company.model";
import {CompanyEditModel} from "../model/company-edit.model";
import {PicTypeEnum} from "../../../service/article/article.service";

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {

  form = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      country: new FormControl('-1', [Validators.required, Validators.min(1)]),
      telephone: new FormControl('', []),
      website: new FormControl('', []),
      email: new FormControl('', []),
      companyId: new FormControl('', [Validators.required])
    }
  );
  userId: number;
  isUserLoggedIn: boolean = true;
  countries$: Observable<{name: string, id: number}[]>;
  headerPic;
  headerPhoto: File;
  headerPhotoType: PicTypeEnum.HEADER;
  constructor(private store: Store<AppState>,
              private companyService: CompanyService,
              private router: Router,
              private locationService: LocationService,
              private activatedRoute: ActivatedRoute) {
    this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userId = user.user.id;
      }
    })
  }

  ngOnInit() {
    if (!this.userId) {
      this.isUserLoggedIn = false;
    }
    this.countries$ = this.locationService.getLocations();
    const cId = this.activatedRoute.snapshot.params.id;

    this.getCompany(cId);
  }

  get name(){
    return this.form.get('name').value;
  }

  get description(){
    return this.form.get('description').value;
  }

  get telephone(){
    return this.form.get('telephone').value;
  }

  get website(){
    return this.form.get('website').value;
  }

  get email(){
    return this.form.get('email').value;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const data: CompanyEditModel = {
        name: this.name,
        description: this.description,
        userId: this.userId,
        countryId: +this.countryId,
        telephone: this.telephone,
        website: this.website,
        email: this.email,
        id: this.companyCtrl.value
    };
      this.companyService.editCompany(data)
        .subscribe((company: CompanyModel) => {
          this.updateForm(company);
        })
    }
  }

  get countryId(): Number{
    return +this.form.get('country').value;
  }

  get countryCtl(): AbstractControl {
    return this.form.get('country');
  }

  get descriptionCtl(): AbstractControl {
    return this.form.get('description');
  }

  get nameCtl(): AbstractControl {
    return this.form.get('name');
  }

  get telephoneCtrl(): AbstractControl {
    return this.form.get('telephone');
  }

  get websiteCtrl(): AbstractControl {
    return this.form.get('website');
  }

  get emailCtrl(): AbstractControl {
    return this.form.get('email');
  }

  get companyCtrl(): AbstractControl {
    return this.form.get('companyId');
  }

  getCompany(id: number) {
    this.companyService.getCompany(id)
      .subscribe((company: CompanyModel) => {
        this.updateForm(company);
      })
  }

  private updateForm(company: CompanyModel) {
    this.nameCtl.setValue(company.name);
    this.descriptionCtl.setValue(company.description);
    this.telephoneCtrl.setValue(company.telephone);
    this.websiteCtrl.setValue(company.website);
    this.emailCtrl.setValue(company.email);
    this.countryCtl.setValue(company.locationId);
    this.companyCtrl.setValue(company.id);
    this.headerPic = company.logo;
  }


  submitPic(photo: any, picType: PicTypeEnum) {
    let form = new FormData();
    if (photo) {
      form.append('pic', photo, photo.name);
    }else{
      return;
    }

    this.companyService.editHeaderPhoto(this.companyCtrl.value, form, PicTypeEnum.HEADER)
      .subscribe(res => {
        //route to the user profile
        this.router.navigate(['/user'])
      })
  }


  onChangePhoto(event, type: PicTypeEnum) {
    switch (type) {
      case PicTypeEnum.HEADER:
        this.headerPhoto = event.target.files[0];
        break;
      default:
        this.headerPhoto = event.target.files[0];
        break;
    }
  }
}
