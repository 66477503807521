import { Component, OnInit } from '@angular/core';
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";

@Component({
  selector: 'app-dating-profile-edit',
  templateUrl: './dating-profile-edit.component.html',
  styleUrls: ['./dating-profile-edit.component.scss']
})
export class DatingProfileEditComponent implements OnInit {

  loggedInUserSub: Subscription;
  loggedInUser: UserDataModel;
  isEditMode = false;
  constructor(private store: Store<AppState>,
              private userService: UserService) {
    this.loggedInUserSub = store.select('user', "user")
      .subscribe((loggedInUser: UserDataModel) => {
        this.loggedInUser = loggedInUser;
        this.isEditMode = this.loggedInUser.singlesNetworkStatus === 1;
      })
  }

  ngOnInit() {
  }

  onLeaveSinglesClick() {

  }
}
