<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row d-flex flex-row justify-content-center">
      <div class="col-12-sm">
        <h1 class="display-4">Email enquiry</h1>
      </div>
    </div>
    <!--<div class="row" *ngIf="isUserLoggedIn">-->
    <div class="row">
      <div class="col-sm-12">
        <form [formGroup]="form">
          <div class="form-group">
            <div class="label">Name</div>
            <div>
              <input class="form-control" type="text" formControlName="name"/>
            </div>
            <div *ngIf="name.touched && name.invalid" class="alert-danger">
              <span>Enter your name</span></div>
          </div>

          <div class="form-group">
            <div class="label">Your Email Address</div>
            <div>
              <input class="form-control" type="text" formControlName="email"/>
            </div>
            <div *ngIf="email.touched && email.invalid" class="alert-danger">
              <span>Enter your email address</span></div>
          </div>

          <div class="form-group">
            <div class="label">Phone (Optional)</div>
            <div>
              <input class="form-control" type="text" formControlName="telephone"/>
            </div>
          </div>

          <div class="form-group">
            <div class="label">Subject</div>
            <div>
              <input class="form-control" type="text" formControlName="subject"/>
            </div>
            <div *ngIf="subject.touched && subject.invalid" class="alert-danger">
              <span>Enter your message subject</span></div>
          </div>

          <!--Description-->
          <div class="form-group">
            <div class="label">Message</div>
            <div>
          <textarea class="form-control" type="text"
                    formControlName="message"
                    rows="5"></textarea>
            </div>
            <div *ngIf="message.touched && message.invalid" class="alert-danger">
              <span>Enter the message</span></div>
          </div>


          <!--submit button-->
          <div class="form-group d-flex flex-row justify-content-center">
            <div>
              <button class="btn btn-lg btn-secondary" (click)="submit()">
                Submit
                <app-spinner *ngIf="isSending" [spinner-options]="{isSpinning: true, size: '1x', classPrimary: true, classSecondary: false}"></app-spinner>
              </button>
            </div>
            <div class="ml-3">
              <a [routerLink]="'/corporate/'+companyId" class="btn btn-lg btn-primary">Back to organisation</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>


