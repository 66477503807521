<div class="row">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <form [formGroup]="formGroup">
        <div class="d-flex justify-content-center"><h1>Create an article</h1></div>

        <!--Header Section-->
        <div>
          <div><h6>Header</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="form-group d-flex flex-column">
                <label for="headline">Headline</label>
                <input id="headline"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="headline">
              </div>
              <div class="form-group d-flex flex-column">
                <label for="video-embed-header">Header video embed url</label>
                <input id="video-embed-header"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="headerVid">
              </div>

              <!--editorial pic-->
              <div class="form-group d-flex flex-column">
                <label for="editorial-pic">Editorial picture</label>
                <input id="editorial-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="headerPic">
              </div>

              <!--display pic-->
              <div class="form-group d-flex flex-column">
                <label for="display-pic">Display picture</label>
                <input id="display-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="displayPic">
              </div>
            </div>
          </div>
        </div>

        <!--Section One-->
        <div class="mt-5">
          <div><h6>Section one</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label>Section one text</label>
                <textarea
                  rows="7"
                  class="form-control"
                  formControlName="section1Body"></textarea>
              </div>
              <div class="form-group d-flex flex-column">
                <label for="para1-video-embed-header">Section one video embed url src</label>
                <input id="para1-video-embed-header"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="section1Vid">
              </div>
              <!--section 2 pic-->
              <div class="form-group d-flex flex-column">
                <label for="section1-pic">Section 1 picture</label>
                <input id="section1-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="section1Img">
              </div>
            </div>
          </div>
        </div>

        <!--Section Two-->
        <div class="mt-5">
          <div><h6>Section two</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label>Section two text</label>
                <textarea
                  rows="7"
                  class="form-control"
                  formControlName="section2Body"></textarea>
              </div>
              <div class="form-group d-flex flex-column">
                <label for="para1-video-embed-header">Section 2 video embed src url</label>
                <input id="para2-video-embed-header"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="section2Vid">
              </div>

              <!--section 2 pic-->
              <div class="form-group d-flex flex-column">
                <label for="section2-pic">Section 2 picture</label>
                <input id="section2-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="section2Img">
              </div>
            </div>
          </div>
        </div>

        <!--Submit Section-->
        <div class="form-group d-flex justify-content-center mt-5">
          <button *ngIf="mode == 'edit'; else templateRef"  class="btn btn-primary btn-lg"
                  (click)="saveEdit()">Edit
          </button>
          <ng-template #templateRef>
            <button  class="btn btn-primary btn-lg"
                     (click)="submit()">Submit
            </button>
          </ng-template>

        </div>
      </form>
    </div>

  </div>
  <div class="col-md-1"></div>
</div>
