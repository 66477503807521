import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {CompanyService} from "../../company/service/company.service";
import {ProductService} from "../../../service/product/product.service";
import {ArticleService} from "../../../service/article/article.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {UserDataModel} from "../../../model/userModel";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {FellowshipModel} from "../../../model/fellowship.model";
import {faDotCircle} from "@fortawesome/free-solid-svg-icons";

enum PageMode {
  HOME_PAGE = 'HOME',
  GALLERY_PAGE = 'GALLERY'
}

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit, OnDestroy {

  userViewableData: UserDataModel;
  userViewableId: number;
  fellowshipPage = 0;
  fellowshipListItems: FellowshipModel[] = [];
  faDotCircle: any;
  pageMode: PageMode = PageMode.HOME_PAGE;

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private activatedRoute: ActivatedRoute) {
    /*icons*/
    this.faDotCircle = faDotCircle;
  }

  ngOnInit() {
    this.userViewableId = this.activatedRoute.snapshot.params.id;
    this.getUser(this.userViewableId);
  }

  ngOnDestroy(): void {
  }

  getUser(userId: number) {
    this.userService.getUser(userId).subscribe((userViewable: UserDataModel) => {
      this.userViewableData = userViewable;
      if (!this.userViewableData.userHeaderPic || this.userViewableData.userHeaderPic.length == 0) {
        this.userViewableData.userHeaderPic = this.userService.userNoPic;
      }
    })
  }



}
