import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectoryRoutingModule } from './directory-routing.module';
import { DirectoryHomeComponent } from './directory-home/directory-home.component';
import { UserDirectoryMgmtComponent } from './user-directory-mgmt/user-directory-mgmt.component';
import {StoreModule} from "@ngrx/store";
import {reducer} from "./directory.reducer";


@NgModule({
  declarations: [DirectoryHomeComponent, UserDirectoryMgmtComponent],
  imports: [
    CommonModule,
    DirectoryRoutingModule,
    StoreModule.forFeature('directory', reducer)
  ],
  exports: [UserDirectoryMgmtComponent]
})
export class DirectoryModule { }
