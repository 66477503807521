import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../service/company.service";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {LocationService} from "../../../service/location/location.service";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {BizCategoryModel} from "../../../model/biz-category.model";

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss']
})
export class CompanyAddComponent implements OnInit {
  form = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      country: new FormControl('-1', [Validators.required, Validators.min(1)]),
      telephone: new FormControl('', []),
      website: new FormControl('', []),
      email: new FormControl('', []),
      bizCategory: new FormControl('-1', [Validators.required, Validators.min(1)]),
    }
  );
  userId: number;
  isUserLoggedIn: boolean = true;
  countries$: Observable<{name: string, id: number}[]>;
  bizCategories$: Observable<BizCategoryModel[]>;

  constructor(private store: Store<AppState>,
              private companyService: CompanyService,
              private router: Router,
              private locationService: LocationService) {
    this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userId = user.user.id;
      }
    })
  }

  ngOnInit() {
    if (!this.userId) {
      this.isUserLoggedIn = false;
    }
    this.countries$ = this.locationService.getLocations();
    this.bizCategories$ = this.companyService.getBizCategories();
  }

  get name(){
    return this.form.get('name').value.trim();
  }

  get description(){
    return this.form.get('description').value.trim();
  }

  get telephone(){
    return this.form.get('telephone').value.trim();
  }

  get website(){
    return this.form.get('website').value.trim();
  }

  get email(){
    return this.form.get('email').value.trim();
  }

  get bizCategory(){
    return +this.form.get('bizCategory').value.trim();
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const data = {
        name: this.name,
        description: this.description,
        userId: this.userId,
        countryId: this.countryId,
        telephone: this.telephone,
        website: this.website,
        email: this.email,
        bizCategoryId: this.bizCategory
      };
      this.companyService.postCompany(data)
        .subscribe(response => {
          this.router.navigate(['/user']);
        });
    }
  }

  get countryId(): Number{
    return +this.form.get('country').value.trim();
  }

  get countryCtl(): AbstractControl {
    return this.form.get('country');
  }

  get bizCategoriesCtl(): AbstractControl {
    return this.form.get('bizCategory');
  }

  get descriptionCtl(): AbstractControl {
    return this.form.get('description');
  }

  get nameCtl(): AbstractControl {
    return this.form.get('name');
  }
}

