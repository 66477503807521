<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row my-3 d-flex justify-content-center">
      <div class="col-12-sm">
        <div class="jumbotron">
          <h1 class="display-4">HealthCare Directory</h1>
          <h1 class="lead">
            Database of businesses and organisations in the health care delivery industry.
            We list organisations, stores and products and allow for easy searching and discovery. We empower B2B discovery
            to facilitate small and large scale businesses in the health care sector.
          </h1>
        </div>
      </div>
    </div>
    <div class="row my-3 px-sm-3">
      <div *ngFor="let company of companies" class="col-sm-12 col-md-4">

        <div class="card mb-4 box-shadow border-0">
          <a [routerLink]="'/page/'+company.id">
            <img class="card-img-top custom-img-fluid shadow"
                 alt="pic"
                 [src]="company.logo"
            >
          </a>
          <div class="card-body pl-0 pr-0 pt-2 pb-0">
            <a [routerLink]="'/page/'+company.id"><h5
              class="card-title">{{company.name}}</h5></a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>





