<div *ngIf="loggedInUser">
  <div class="d-flex flex-row justify-content-center border-secondary bor w-75">
    <button class="btn btn-block btn-primary btn-lg" *ngIf="loggedInUser.singlesNetworkStatus === 1; else nonDating"
    (click)="onLeaveSinglesClick()">Leave dating network</button>
    <ng-template #nonDating>
      <button class="btn btn-lg" *ngIf="loggedInUser.singlesNetworkStatus === 1; else nonDating" >Join dating network</button>
    </ng-template>
  </div>
  <div *ngIf="isEditMode">
    Update your dating profile information
  </div>
  <div></div>
</div>
