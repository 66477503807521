import {Component, Input, OnInit} from '@angular/core';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input('spinner-options')
  options: {
    isSpinning: boolean;
    size?: string;
    classSecondary?: boolean;
    classPrimary?: boolean;
  } = {isSpinning: true, size: '5x', classSecondary: true, classPrimary: false};
  faSpinner: any;
  constructor() {
    this.faSpinner = faSpinner;
  }

  ngOnInit() {
  }

}
