import { Component, OnInit } from '@angular/core';
import {CommunityModel} from "../../../model/community.model";
import {CommunityService} from "../../../service/community/community.service";

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.scss']
})
export class CommunityListComponent implements OnInit {

  communities: CommunityModel[] = [];
  constructor(private communityService: CommunityService) { }

  ngOnInit(): void {
    this.communityService.getCommunities()
      .subscribe(value => {
        this.communities = value;
      });
  }

}
