import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FellowshipModel} from "../../model/fellowship.model";
import {Observable} from "rxjs";
import {FellowshipTransformer} from "../../transformer/fellowship-transformer";
import {map} from "rxjs/operators";
import {FellowshipreplyModel} from "../../model/fellowshipreply.model";
import {ForumTypeEnum} from "../../model/forum-type.enum";

@Injectable({
  providedIn: 'root'
})
export class FellowshipService {

  constructor(private httpClient: HttpClient) {

  }

  getFellowshipList(page: number, type: ForumTypeEnum): Observable<FellowshipModel[]>{
    let url = `/ng/fellowship/list/${type}/${page}`;
    if (type == ForumTypeEnum.ALL) {
      url = `/ng/fellowship/list/${page}`;
    }

    return this.httpClient.get<FellowshipModel[]>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipModelArray(response)
        })
      );
  }

  getFellowshipListForUser(userId: number, page: number): Observable<FellowshipModel[]>{
    const url = `/ng/fellowship/user/${userId}/list/${page}`;
    return this.httpClient.get<FellowshipModel[]>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipModelArray(response)
        })
      );
  }

  getFellowshipListForOrganisation(orgId: number): Observable<FellowshipModel[]>{
    const url = `/ng/fellowship/org/${orgId}`;
    return this.httpClient.get<FellowshipModel[]>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipModelArray(response)
        })
      );
  }

  postFellowship(formData: FormData): Observable<FellowshipModel> {
    const url = `/ng/fellowship`;
    return this.httpClient.post<FellowshipModel>(url, formData)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowship(response)
        })
      );
  }

  postFellowshipReply(comment: string, userId: number, fellowshipId: number): Observable<FellowshipreplyModel> {
    const url = `/ng/fellowshipreply`;
    return this.httpClient.post<FellowshipModel>(url, {
      userId: userId,
      fellowshipId: fellowshipId,
      comment: comment

    })
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipReply(response)
        })
      );
  }
}
