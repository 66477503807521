<div *ngFor="let articleItem of articles" class="row mt-3">
  <div class="col-md-1"></div>
  <div class="col-sm-10 col-md-10 p-sm-3">
    <!--<a [routerLink]="'/news/'+newsItem?.id">
      <img *ngIf="newsItem?.displayPic" class="card-img-top custom-img-fluid shadow"
           alt="article pic"
           [src]="newsItem?.displayPic"
      >
    </a>-->
    <div class="card-body pl-0 pr-0 pt-2 pb-0">
      <a [routerLink]="'./article/'+articleItem?.id">
        <h2>{{articleItem?.headline}}</h2>
      </a>
    </div>
  </div>
  <div class="col-md-1"></div>
</div>

