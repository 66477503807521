import { Component, OnInit } from '@angular/core';
import {VodcastModel} from "../../../model/vodcast.model";
import {ActivatedRoute} from "@angular/router";
import {VodcastService} from "../../../service/vodcast/vodcast.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-user-view-vids',
  templateUrl: './user-view-vids.component.html',
  styleUrls: ['./user-view-vids.component.scss']
})
export class UserViewVidsComponent implements OnInit {

  userId: number;
  vodcasts: VodcastModel[];
  constructor(private activatedRoute: ActivatedRoute,
              private vodcastService: VodcastService,
              private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.parent.params.id;
    this.getVideos();
  }

  getVideos(){
    this.vodcastService.getAllVodcastForUser(this.userId)
      .subscribe((vodcasts: VodcastModel[]) => {
        this.vodcasts = vodcasts;
      })
  }

  sanitiseVideoUrl(vid){
    return this.sanitizer.bypassSecurityTrustResourceUrl(vid);
  }
}
