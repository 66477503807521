import { Component, OnInit } from '@angular/core';
import {CommunityModel} from "../../../model/community.model";
import {CommunityService} from "../../../service/community/community.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-community-about',
  templateUrl: './community-about.component.html',
  styleUrls: ['./community-about.component.scss']
})
export class CommunityAboutComponent implements OnInit {
  community: CommunityModel;

  constructor(private communityService: CommunityService,
              private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    const communityName = this.activatedRoute.snapshot.parent.params.name;
    const id = this.activatedRoute.snapshot.parent.params.id;
    this.getCommunity(id,communityName);
  }

  getCommunity(id:number,name: string) {
    this.communityService.getCommunity(id,name)
      .subscribe((value: CommunityModel) => {
        this.community = value;
      });
  }

}
