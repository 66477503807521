import {Injectable} from '@angular/core';
import {UserDataModel} from "../../model/userModel";
import {Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {UserTransformer} from "../../transformer/user-transformer";
import {environment} from "../../../environments/environment.prod";
import {CookieService} from "ngx-cookie-service";
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import {DeleteUserAction, SetUserAction} from "../../component/user/user.action";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly userNoPic = 'https://s3-eu-west-1.amazonaws.com/christianity.ng-system/no_image.gif'

  constructor(private httpClient: HttpClient,
              private store: Store<AppState>,
              private cookieService: CookieService) {
  }

  getUser(userId: Number): Observable<UserDataModel> {
    return this.httpClient.get(`/ng/user/${userId}`)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  getUserByEmail(userEmail: string): Observable<UserDataModel> {
    return this.httpClient.get(`/ng/rest/user/email/${userEmail}`)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  storeUserAndWriteCookie(user: UserDataModel) {
    this.store.dispatch(new SetUserAction({user: user}));
    this.cookieService.set(
      environment.userIdCookieName,
      user.id + '',
      1
    );
  }

  deleteUserCookies() {
    this.cookieService.delete(environment.jwtTokenCookieName);
    this.cookieService.delete(environment.userIdCookieName);
    this.store.dispatch(new DeleteUserAction());
  }

  deactivateUser(userId: number): Observable<UserDataModel> {
    return this.httpClient.put(`/ng/user/deactivate/${userId}`, null)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateNotificationSettings(prayerNotification: number,
                             newsNotification: number,
                             editorialNotification: number,
                             userId: number): Observable<UserDataModel> {
    const bdy = {
      prayer: prayerNotification,
      news: newsNotification,
      editorial: editorialNotification,
      userId: userId
    }
    return this.httpClient.put(`/ng/user/notification`, bdy)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateUserProfile(userId: number,
                    data: {
                      name: string;
                      about: string
                      locationId: number
                    }
  ): Observable<UserDataModel> {

    return this.httpClient.put(`/ng/user/${userId}`, data)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateUserProfilePicture(url: string, userId: number): Observable<UserDataModel> {
    return this.httpClient.put(`/ng/user/${userId}/profilePic`, {profilePicUrl: url})
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  deleteUserPicture(picId: number, userId: number): Observable<UserDataModel> {
    return this.httpClient.get(`/ng/picture/${picId}/${userId}/delete`)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updatePassword(pw: string, userId: number): Observable<UserDataModel>{
    return this.httpClient.put(`/ng/user/${userId}/password/${pw}`, null)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  getDatingNetworkUsers(pageNumber: number): Observable<UserDataModel[]> {
    return this.httpClient.get(`/ng/user`, {
      params: {'singlesOnly': 'true', 'nonSinglesOnly': 'false', 'pageNumber': `${pageNumber}`}
    })
      .pipe(
        map((json: any[]) => {
            return UserTransformer.convertToUsers(json);
          },
          catchError(error => throwError(error)))
      );
  }
}
