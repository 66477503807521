import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserDataModel, UserModel} from "../../model/userModel";
import {UserTransformer} from "../../transformer/user-transformer";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {SignupModel} from "../../model/signup.model";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private httpClient: HttpClient) {
  }

  postRegistration(user: SignupModel): Observable<UserDataModel> {
    return this.httpClient.post("/ng/registration", user)
      .pipe(
        map((jsonBody: any) => {
            return UserTransformer.convertToUser(jsonBody);
          },
          catchError((response: Response) => throwError(response))
        ));
  }

  postEmailVerificationCode(userId: number, verificationCode: number): Observable<{}> {
    const url = `/ng/user/email/verify/${userId}/${verificationCode}`;
    return this.httpClient.put(url, {})
  }

  doRegCompleteReminder(userId: number): Observable<UserModel> {
    const url = `/ng/user/${userId}/reg_complete`;
    return this.httpClient.get<UserModel>(url);
  }
}
