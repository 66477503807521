import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {VodcastModel} from "../../model/vodcast.model";
import {Observable} from "rxjs";
import {VodcastTransformer} from "../../transformer/vodcast-transformer";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VodcastService {

  constructor(private httpClient: HttpClient) { }

  getAllVodcastForOrganisation(organisationId: number): Observable<VodcastModel[]> {
    return this.httpClient.get(`/ng/vodcast/organisation/${organisationId}`)
      .pipe(
        map(res => {
          return VodcastTransformer.transformArrToModel(res)
        })
      )
  }

  getAllVodcastForUser(userId: number): Observable<VodcastModel[]> {
    return this.httpClient.get(`/ng/vodcast/user/${userId}`)
      .pipe(
        map(res => {
          return VodcastTransformer.transformArrToModel(res)
        })
      )
  }
}
