import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthenticationModel} from "../../model/userModel";
import {Observable, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {AuthenticationTransformer} from "../../transformer/authentication-transformer";
import {Store} from "@ngrx/store";
import {AppState} from "../../app.state";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient,
              private store: Store<AppState>) {
  }

  postLogin(loginData: { email: string, password: string }): Observable<AuthenticationModel> {
    return this.httpClient.post('/ng/login', loginData).pipe(
      map(jsonBody => {
          return AuthenticationTransformer.convertJsonToAuthentication(jsonBody);
        },
        catchError((response: Response) => throwError(response)))
    );
  }

  getCountries(): Observable<{ name: string, id: number }[]> {
    return this.httpClient.get<{ name: string, id: number }[]>('ng/country/list');
  }

  getResetPasswordCode(email: string): Observable<any> {
    const url = `/ng/notification/password/code/${email}`;
    return this.httpClient.post(url, null);
  }

  resetPassword(data: {email: string, code: number}): Observable<any> {
    const url = `/ng/login/password/reset`;
    return this.httpClient.post(url, data);
  }
}
