import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CommunityHomeComponent} from "./community-home/community-home.component";
import {CommunityListComponent} from "./community-list/community-list.component";
import {CommunityNewsComponent} from "./community-news/community-news.component";
import {CommunityServiceComponent} from "./community-service/community-service.component";
import {CommunityAboutComponent} from "./community-about/community-about.component";
import {ArticleComponent} from "../article/article/article.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: 'communities',
    pathMatch: 'full'
    // component: CommunityHomeComponent
  },
  {
    path: 'c/:id/:name',
    component: CommunityHomeComponent,
    children: [
      {
        path: '',
        component: CommunityNewsComponent
      },{
        path: 'services',
        component: CommunityServiceComponent
      },{
        path: 'about',
        component: CommunityAboutComponent
      },{
        path: 'article/:id',
        component: ArticleComponent
      }
    ]
  },{
    path: 'communities',
    component: CommunityListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityRoutingModule { }
