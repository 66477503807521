import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommunityRoutingModule } from './community-routing.module';
import { CommunityHomeComponent } from './community-home/community-home.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {MomentModule} from "ngx-moment";
import {Nl2BrPipeModule} from "nl2br-pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {SpinnerModule} from "../spinner/spinner.module";
import { CommunityListComponent } from './community-list/community-list.component';
import { CommunityNewsComponent } from './community-news/community-news.component';
import {MatDialogModule} from "@angular/material/dialog";
import { CommunityAboutComponent } from './community-about/community-about.component';
import { CommunityServiceComponent } from './community-service/community-service.component';


@NgModule({
  declarations: [CommunityHomeComponent, CommunityListComponent, CommunityNewsComponent, CommunityAboutComponent, CommunityServiceComponent],
    imports: [
        CommonModule,
        CommunityRoutingModule,
        FormsModule,
        HttpClientModule,
        MomentModule,
        Nl2BrPipeModule,
        FontAwesomeModule,
        SpinnerModule,
        ReactiveFormsModule
    ],
  exports: [],
})
export class CommunityModule { }
