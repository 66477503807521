<div>
  <div class="row p-5 mt-3 user-header">
    <div class="col-sm-12 col-md-4">
      <img class="card-img-top rounded-circle shadow-lg"
           alt="header image"
           [src]="company?.logo"
           data-holder-rendered="true"
           style="height: 225px;
                 width: 100%;
                 display: block;"/>
    </div>
    <div class="col-sm-12 col-md-8">
      <h2 class="bol">{{company?.name}}</h2>
      <div>Tel: <span>{{company?.telephone}}</span></div>
      <div *ngIf="notNull(company?.email)" class="mt-3">
        <a [routerLink]="'/corporate/email/enquiry/'+company.id" class="btn-secondary btn btn-lg">
          <fa-icon [icon]="faEnvelope"></fa-icon>
          <span class="ml-2 text-white">Email</span>
        </a>
      </div>
    </div>
  </div>
  <ul class="nav justify-content-center py-2 border-dark border-top border-bottom shadow">
    <li class="nav-item">
      <a class="btn btn-secondary d-inline-block" [routerLink]="'./'">
        <span>Home</span>
      </a>
      <fa-icon class="mx-2 fontawesome-color" [icon]="faDotCircle"></fa-icon>
      <a class="btn btn-secondary d-inline-block" [routerLink]="'products'">
        <span>Products</span>
      </a>
      <fa-icon class="mx-2 fontawesome-color" [icon]="faDotCircle"></fa-icon>
      <a class="btn btn-secondary d-inline-block" [routerLink]="'vodcast'">
        <span>Videos</span>
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
