<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row">
      <div class="col-12-sm">
        <h1 class="display-4">Add organisation information</h1>
      </div>
    </div>
    <!--<div class="row" *ngIf="isUserLoggedIn">-->
    <div class="row">
      <div class="col-sm-12">
        <form [formGroup]="form">
          <div class="form-group">
            <div class="label">Name</div>
            <div>
              <input class="form-control" type="text" formControlName="name"/>
            </div>
            <div *ngIf="nameCtl.touched && nameCtl.invalid" class="alert-danger"><span>Enter name</span></div>
          </div>

          <!--Description-->
          <div class="form-group">
            <div class="label">Description</div>
            <div>
          <textarea class="form-control" type="text"
                    formControlName="description"
                    rows="5"></textarea>
            </div>
            <div *ngIf="descriptionCtl.touched && descriptionCtl.invalid" class="alert-danger"><span>Enter description</span></div>
          </div>

          <!--business category-->
          <div id="bizcategory" class="form-group">
            <div class="label">Category</div>
            <select class="form-control" formControlName="bizCategory">
              <option value="-1">Please Select</option>
              <option *ngFor="let bizCategory of ( bizCategories$ | async )" value="{{bizCategory.id}}">
                {{bizCategory.name}}
              </option>
            </select>
            <div *ngIf="bizCategoriesCtl.touched && bizCategoriesCtl.invalid" class="alert-danger"><span>Select a category</span></div>
          </div>


          <!--location-->
          <div id="countriy" class="form-group">
            <div class="label">Location</div>
            <select class="form-control" formControlName="country">
              <option value="-1">Please Select</option>
              <option *ngFor="let country of ( countries$ | async )" value="{{country.id}}">
                {{country.name}}
              </option>
            </select>
            <div *ngIf="countryCtl.touched && countryCtl.invalid" class="alert-danger"><span>Select a location</span></div>
          </div>

          <!--Telephone-->
          <div class="form-group">
            <div class="label">Telephone</div>
            <div>
                <input type="text" class="form-control"
                    formControlName="telephone"
                    />
            </div>
          </div>

          <!--website-->
          <div class="form-group">
            <div class="label">Website</div>
            <div>
                <input type="text" class="form-control"
                    formControlName="website"
                    />
            </div>
          </div>


          <!--Email-->
          <div class="form-group">
            <div class="label">Email</div>
            <div>
              <input type="text" class="form-control"
                     formControlName="email"
              />
            </div>
          </div>


          <!--Telephone-->
          <!--<div class="form-group">
            <div class="label">Telephone</div>
            <div>
              <input type="text" class="form-control"
                     formControlName="telephone"
              />
            </div>
          </div>-->


          <!--submit button-->
          <div class="form-group">
            <div>
              <button class="btn btn-primary" (click)="submit()">Submit</button>
            </div>
          </div>
        </form>
      </div>

    </div>
    <div class="row" *ngIf="!isUserLoggedIn">
      <div class="col-sm-12">
        <h1>User is not logged in. You must log in before adding organisation details</h1>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>


