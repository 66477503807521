<div>
  <div class="community-name-container" style="">
      <!--<div *ngIf="community.logoUrl">
        <img class="card-img-top rounded-circle shadow-lg"
             alt="header image"
             [src]="community?.logoUrl"
             data-holder-rendered="true"
             style="height: 225px;
                 width: 100%;
                 display: block;"/>
      </div>-->
      <span class="display-4">{{community?.name}}</span>

  </div>
  <ul class="nav justify-content-center py-2 border-dark border-top border-bottom shadow">
    <li class="nav-item">
      <a class="btn btn-secondary d-inline-block" [routerLink]="'./'">
        <span>News</span>
      </a>
      <fa-icon class="mx-2 fontawesome-color" [icon]="faDotCircle"></fa-icon>
      <a class="btn btn-secondary d-inline-block" [routerLink]="'services'">
        <span>Services</span>
      </a>
      <fa-icon class="mx-2 fontawesome-color" [icon]="faDotCircle"></fa-icon>
      <a class="btn btn-secondary d-inline-block" [routerLink]="'about'">
        <span>About</span>
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
